import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DeSanitizeMobileNumber } from '../directives/DeSanitizeMobileNumber';
import { DeSanitizeOHIPNumber } from '../directives/DeSanitizeOHIPNumber';
import { AuthenticationService } from '../services/authentication.service';
import { PharmacyService } from '../services/pharmacy.service';
import { GlobalConstants } from '../shared/global-constants';
import { Pharmacy } from '../shared/models/pharmacy.model';
import { User } from '../shared/models/user.model';
declare var Compress: any;
declare var $: any;
@Component({
  selector: 'mylocalrx-contentcontainer-profile',
  templateUrl: './mylocalrx-contentcontainer-profile.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-profile.component.scss']
})
export class MylocalrxContentcontainerProfileComponent implements OnInit {
  compress = new Compress()
  profileImage = "./../../assets/images/user.png";
  isEditing = false;
  haveinsurance = false;
  currentUser: User = {} as User;
  userModel: any = null;
  passModel: any = { oldPassword: "", newPassword: "", confirmPassword: "" };
  pharmacyList = [{} as Pharmacy];
  hasUserImage = false;
  constructor(private userService: AuthenticationService, private pharmacyService: PharmacyService, private zone: NgZone, private sanitizer: DomSanitizer, private deSanitizeMobile: DeSanitizeMobileNumber,private deSanitizeOHIPNumber:DeSanitizeOHIPNumber) {

    userService.logInSuccess.subscribe(x => {
      this.currentUser = x;

      var that = this;
      if (this.currentUser) {
        $('.csLoader').css({ display: 'flex' });
        userService.getCurrentUserById(this.currentUser.id).subscribe(x => {
          this.userModel = x;
          if (this.userModel.profileImage) {
            this.profileImage = this.userModel.profileImage;
          }
          this.userModel.phoneNumber = this.deSanitizeMobile.transform(this.userModel.phoneNumber, true);
          this.userModel.ohip = this.deSanitizeOHIPNumber.transform(this.userModel.ohip, true);
          this.hasUserImage = this.profileImage.indexOf("http") > -1;
          this.haveinsurance = this.userModel.insuranceFilePath
          this.getBlobData();
          this.onChangePharmacy(this.userModel.preferredPharmacy);
          $('.csLoader').hide();
          $(document).ready(function () {
            $('.agedatepicker').datepicker().on("changeDate", function (e: any) {
              that.zone.run(x => {
                that.userModel.dateOfBirth = $('.agedatepicker').val();
              });
            });
          });
        }, err => {
          $('.csLoader').hide();
        });
      }
    });
    this.pharmacyService.getAllMyLocarxEnable().subscribe(data => {
      this.pharmacyList = data.filter(x=>x.myLocalRXEnabled == true);
      if (this.userModel) {
        this.onChangePharmacy(this.userModel.preferredPharmacy);
      }
    });
  }
  ohipFileList: any[] = [];
  insuranceFileList: any[] = [];
  getBlobData() {
    if (this.userModel.ohippath) {
      for (var i = 0; i < this.userModel.ohippath.length; i++) {
        var url = this.sanitizer.bypassSecurityTrustResourceUrl(this.userModel.ohippath[i]);
        var type = this.userModel.ohippath[i].indexOf('.pdf') > -1 ? 1 : this.userModel.ohippath[i].indexOf('.tiff') > -1 ? 2 : 3;
        this.ohipFileList.push({ url: url, type: type });
      }
    }
    if (this.userModel.insuranceFilePath) {
      for (var i = 0; i < this.userModel.insuranceFilePath.length; i++) {
        var url = this.sanitizer.bypassSecurityTrustResourceUrl(this.userModel.insuranceFilePath[i]);
        var type = this.userModel.insuranceFilePath[i].indexOf('.pdf') > -1 ? 1 : this.userModel.insuranceFilePath[i].indexOf('.tiff') > -1 ? 2 : 3;
        this.insuranceFileList.push({ url: url, type: type });
      }
    }
  }
  ngOnInit(): void {

  }

  saveData(form: NgForm) {
    var dt = new Date(this.userModel.dateOfBirth);
    var minDate = new Date(1900, 0, 1);
    var maxDate = new Date();
    if (dt < minDate || dt >= maxDate) {
      $('#dateOfBirth').focus();
      alert("Please enter a valid date, min. date JAN/01/1900");
      return;
    }
    if (!form.form.valid) {
      for (const field in form.form.controls) {
        const control = form.form.get(field);
        if (control?.invalid) {
          control.markAsTouched();
          $('#' + field).focus();
        }
      }
      return;
    }
    let ohipFile = $('#ohipFile')[0] ? this.ohipFiles : "";
    let insuranceFile = $('#insuranceFile')[0] ? this.insuranceFiles : null;
    if (!this.haveinsurance) {
      insuranceFile = null;
      this.userModel.insuranceFilePath = null;
    }
    this.UpdateUser(ohipFile, insuranceFile);
  }
  ohipFiles: any[] = [];
  insuranceFiles: any[] = [];
  profileImageFile: any;
  private UpdateUser(ohipFile: any, insuranceFile: any) {
    $('.csLoader').css({ display: 'flex' });
    this.userModel.phoneNumber = this.deSanitizeMobile.transform(this.userModel.phoneNumber);
    this.userModel.ohip = this.deSanitizeOHIPNumber.transform(this.userModel.ohip);
    var data = JSON.parse(JSON.stringify(this.userModel)); 
    delete data['insuranceFileImage'];
    delete data['ohippathImage'];
    this.userService.updateUser(data, ohipFile, insuranceFile).subscribe(x => {
      debugger;
      $('.csLoader').hide();
      this.isEditing = false;

      this.currentUser.firstName = this.userModel.firstName;
      this.currentUser.lastName = this.userModel.lastName;
      this.currentUser.phoneNumber = this.userModel.phoneNumber;
      this.currentUser.ohip = this.userModel.ohip;
      this.currentUser.preferredPharmacy = this.userModel.preferredPharmacy;
      this.UpdateLocalUser(this.currentUser);
    }, error => {
      $('.csLoader').hide();
      console.log(error);
    });
  }

  fileChangeEvent(event: any, id: string) {
    if (event.target.files) {
      const files1 = [...event.target.files]
      this.compress.compress(files1, {
        size: 4,
        quality: .75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      }).then((data: any[]) => {
        console.log(data);
        var newFiles: any = [];
        data.forEach(element => {
          // newFiles.push(this.base64ToFile(element.prefix + element.data, new Date().getTime() + ".jpeg"));
          var nm = new Date().getTime() + "." + element.ext.split('/')[1];
          var baseString = element.data;
          debugger;
          if (baseString.indexOf("data") < 0) {
            baseString = "data:" + element.ext + ";base64," + baseString;
          }
          newFiles.push(this.base64ToFile(baseString, nm));
        });
        if (id === "ohipFile") {
          this.ohipFiles = newFiles;
        }
        else if (id === "insuranceFile") {
          this.insuranceFiles = newFiles;
        }
        else if (id == "profileImage") {
          this.profileImage = data[0].prefix + data[0].data;
          this.profileImageFile = newFiles[0];
          this.SaveProfileImage();
        }
      })
    }
  }

  base64ToFile(dataURI: any, fileName: any): File {

    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], fileName, { type: mimeString });
  }



  Showpassword(password: any, target: any) {
    $($(password)[0]).attr('type', $($(password)[0]).attr('type') == "password" ? 'text' : 'password');

    $(target).toggleClass("fa-eye");
    $(target).toggleClass("fa-eye-slash");
  }
  farmacyAddress = "";
  onChangePharmacy(ev: any) {
    var fa = this.pharmacyList.find(x => x.id === ev);
    console.log(fa);
    this.farmacyAddress = fa ? fa.add1 + " " + fa.pin : "";
  }

  EditClick() {
    this.isEditing = true;
    setTimeout(() => {
      $("#ohipFile").fileinput({
        showCaption: false,
        dropZoneEnabled: false,
        showUpload: false,
        maxFileCount: 3,
        allowedFileExtensions: ['jpg', 'jpeg', 'png', 'tiff', 'pdf'],    // allow only images
        showCancel: false,
        initialPreviewAsData: true,
        browseLabel: 'Upload OHIP...',
        color:"green",
        msgFileRequired: 'You must select OHIP Card copy to upload here.',
        msgFilesTooMany: "You can upload only 3 images",

        maxFileSize: 10000
      });
      $("#insuranceFile").fileinput({
        showCaption: false,
        dropZoneEnabled: false,
        showUpload: false,
        maxFileCount: 6,
        allowedFileExtensions: ['jpg', 'jpeg', 'png', 'tiff', 'pdf'],   // allow only images
        showCancel: false,
        initialPreviewAsData: true,
        browseLabel: 'Upload Insurance Card...',
        msgFileRequired: 'You must select insurance Card copy to upload here.',
        msgFilesTooMany: "You can upload only 6 images",
        resizeImage: true,
        resizeQuality: 0.75,
        //maxFileSize:1000,
        resizeDefaultImageType: 'image/jpeg',
        resizeIfSizeMoreThan: 10000
      });
    }, 200);

  }
  onChangeOHIP(target: any) {
    if (this.userModel.ohip) {
      $(target).parent().parent().removeClass('fileNotRquired2');
    } else {
      $(target).parent().parent().addClass('fileNotRquired2');
    }
  }
  loadImage() {
    $('#profileImage').click();
  }
  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.profileImage = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  resetPasswordError = false;
  changePassword(passwordForm: NgForm) {
    let isError = false;

    if (!passwordForm.form.valid) {
      for (const field in passwordForm.form.controls) {
        const control = passwordForm.form.get(field);

        if (control?.invalid && !isError) {
          isError = true;
          control.markAsTouched();
          $('#' + field).focus();
        }
      }
      return;
    }

    $('.csLoader').css({ display: 'flex' });
    this.userService.updatePassword(this.currentUser.id, { oldPassword: this.passModel.oldPassword, newPassword: this.passModel.newPassword }).subscribe(x => {
      $('.csLoader').hide();
      this.resetPasswordError = !x.errorCode;
      if (!x.errorCode) {
        $("#changePasswordModal").modal('toggle');;
        setTimeout(() => {

          $("#changePasswordModalSuccess").modal('toggle');;
        }, 200);
      }
    }, e => {
      this.resetPasswordError = true;
      $('.csLoader').hide();
    });
  }
  removeImage() {
    this.userModel.profileImage = "";
    this.UpdateUser(null, null);
    this.profileImage = "./../../assets/images/user.png";
    this.hasUserImage = false;
  }
  SaveProfileImage() {
    $('.csLoader').css({ display: 'flex' });
    this.userService.uploadProfile(this.profileImageFile).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      if (x.id) {
        this.UpdateLocalUser(x);
      }
    }, error => {
      $('.csLoader').css({ display: 'none' });
    });
  }

  private UpdateLocalUser(x: User) {
    var current = x;
    current.token = GlobalConstants.getcurrentUser()?.token!;
    localStorage.setItem(GlobalConstants.CURRENT_USER, JSON.stringify(current));
    this.userModel = current;
    this.userService.logInSuccess.next(this.currentUser);
  }
  deRegisterMsg= "";
  deRegister(){
    this.deRegisterMsg = "";
    $('.csLoader').css({ display: 'flex' });
    this.userService.deRegister(this.currentUser.id).subscribe(x=>{
      this.deRegisterMsg = "Your MyLocarRx account has been de-registered";
      $("#deRegisterSuccess").modal('toggle');
      $('.csLoader').css({ display: 'none' });
    },err=>{
      this.deRegisterMsg = "Something went wrong try again.";
      $("#deRegisterSuccess").modal('toggle');
      $('.csLoader').css({ display: 'none' });
    });
  }
}
