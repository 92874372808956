import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { PharmacySchedule } from '../shared/models/schedual.modal';
import { Appointment } from '../shared/models/Appointment';
import { PharmacyScheduleResponseForClient } from '../shared/models/PharmacyScheduleResponseForClient.model';
import { Questionnaire } from '../shared/models/QuestionnaireModel';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {


  public SCHEDULE_API = environment.baseUrl + '/schedule';
  public APPOINTMENT_API = environment.baseUrl + '/appointment';
  public WAITING_API = environment.baseUrl + '/appointment/waiting';
  public QUESTIONNAIRE_API = environment.baseUrl + '/questionnaire';
  public AddminorAilments_API = environment.baseUrl +'/api/v1/addMinorAilmentAssesment';
  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  getQuestionnaire(id: any): Observable<Questionnaire[]> {
    let result: Observable<any>;
    result = this.http.get(this.QUESTIONNAIRE_API + "/" + id);
    return result;
  }getScheduleForPharmacy(id: any): Observable<[PharmacySchedule]> {
    let result: Observable<any>;
    result = this.http.get(this.SCHEDULE_API + "/" + id);
    return result;
  }

  getAllScheduleWithPharmacy(serviceType:number): Observable<[PharmacyScheduleResponseForClient]> {
    let result: Observable<any>;
    result = this.http.get(this.SCHEDULE_API + "/forClient/" + serviceType);
    return result;
  }

  addStatusofBookedMinorailments(appointment_id:any,assessment_status:any,patientID: any,serviceID:any,pharmacyID:any) {
    // algorithm_name=allergic_rhinitis minorElementPrecription
    let result: Observable<any>;
    // result = this.http.get(`${environment.baseUrl}/api/v1/v1/addMinorAilmentAssesment/`);
    result = this.http.post(this.AddminorAilments_API, { appointment_id:appointment_id, assessment_status:assessment_status,patientID: patientID,serviceID:serviceID,pharmacyID: pharmacyID});
    return result;
}

  getAppointmentForDay(app: Appointment, date: string): Observable<[Appointment]> {
    var dt = new Date(parseInt(date.split("/")[2]), (parseInt(date.split("/")[0]) - 1), parseInt(date.split("/")[1]), 0, 0, 0, 0);
    var d = this.datePipe.transform(new Date(dt.getTime() + (dt.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    let result: Observable<any>;
    result = this.http.get(this.APPOINTMENT_API + `?pharmacyID=${app.pharmacyID}&serviceID=${app.serviceID}&date=${d}`);
    return result;
  }
  getMyAppoinments(patientID: string): Observable<[Appointment]> {
    let result: Observable<any>;
    result = this.http.get(this.APPOINTMENT_API + `?pharmacyID=&patientID=${patientID}&me=true`);
    return result;
  }
  getCovidSchedule(): Observable<[PharmacySchedule]> {
    let result: Observable<any>;
    result = this.http.get(this.APPOINTMENT_API + `/covid/schedule`);
    return result;
  }

  save(appointment: Appointment, cardiaRequestObject: any, isForCovid = false): Observable<any> {
    let result: Observable<any>;
    if (cardiaRequestObject != null) {
      result = this.http.post(this.APPOINTMENT_API + "/cardia", { appointment: appointment, cardiaRequestObject: cardiaRequestObject });
    }
    else {
      if (isForCovid) {
        result = this.http.post(this.APPOINTMENT_API + "/covid", appointment);
      }
      else {
        result = this.http.post(this.APPOINTMENT_API, appointment);
      }
    }
    return result;
  }
  cancelBooking(id: string): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.APPOINTMENT_API + `/cancel/${id}`, null);
    return result;
  }

  addWaitingList(pharmacyID: string, patientID: string,serviceID:string, createdBy: string): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.WAITING_API, { pharmacyID: pharmacyID, patientID: patientID,serviceID:serviceID, createdBy: createdBy });
    return result;
  }
  getMyWaitingList( patientID: string): Observable<any> {
    let result: Observable<any>;
    result = this.http.get(this.WAITING_API+"/patient/"+patientID);
    return result;
  }

  addNewQuestion(quest:any): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.QUESTIONNAIRE_API, quest);
    return result;
  }

}