import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { ProductService } from './services/product.service';
import { GlobalConstants } from './shared/global-constants';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'mylocalrx';

  constructor(private router: Router, private pService: ProductService, private authenticationService: AuthenticationService,) {
  //  window.location.href = 'https://pharmacy.wize.health/';
    GlobalConstants.getCurrentSessionId();
    console.log("prod_mode2");
    router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        $("#toTopBtn").click();
      }
    });
  }

  ngOnInit() { }
  clearAndForgot() {
    localStorage.removeItem(GlobalConstants.CURRENT_USER);
    this.router.navigate(['/login/true']);

  }
  email = "";
  password = "";
  error = "";
  signin(form: NgForm) {
    this.error = "";
    if (!form.form.valid) {
      for (const field in form.form.controls) {
        const control = form.form.get(field);
        if (control?.invalid) {
          control.markAsTouched();
        }
      }
      return;
    }
    $('.csLoader').css({ display: 'flex' });
    GlobalConstants.isLoggedIn = false;
    var _user = JSON.parse(JSON.stringify(form.form.value));
    if (_user.email.indexOf("@") <= 0) {
      _user.email = "+1" + _user.email;
    }
    this.authenticationService.signin(_user).subscribe(result => {
      $('.csLoader').hide();
      console.log(result);
      if (result.token) {
        localStorage.setItem(GlobalConstants.CURRENT_USER, JSON.stringify(result));
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
      else {
        this.error = "Invalid email/phone number or password...";
      }

    }, error => { $('.csLoader').hide(); console.log(error); this.error = "Something went wrong, try agian." });
  }
  isHomeRoute() {
    return this.router.url === '/';
  }
  // addProducts(){
  //   if(this.listProducts.length>0){
  //     var pp = this.listProducts.splice(0,1);

  //     this.pService.addProduct(pp[0]).subscribe(x=>{
  //       console.log(x);
  //       setTimeout(() => {
  //         this.addProducts();
  //       }, 1000);
  //     });
  //   }
  // }


  // listProducts = [
  //   {
  //     productCode:"1111111", productName:"Morpheme Remedies Garcinia Green Tea - 500mg Extract 60's",   productBrand:"Morpheme",  productCompany:"",  shortDesc:"", longDesc:"Mkt: Morpheme Remedies Pvt Ltd <br/> Country of Origin: Canada<br/> Delivery charges if applicable will be applied at checkout",  cost:500,  discount:10,  price:500,  currency:"$",  productImages:["https://www.netmeds.com/images/product-v1/600x600/860987/morpheme_remedies_garcinia_green_tea_500mg_extract_60_s_1.jpg"]
  //   },
  //   {
  //     productCode:"222222", productName:"Aroma Magic Turmeric Cleanser 200 gm",   productBrand:"Morpheme",  productCompany:"Morpheme",  shortDesc:"", longDesc:"Mkt: Morpheme Remedies Pvt Ltd <br/> Country of Origin: Canada<br/> Delivery charges if applicable will be applied at checkout",  cost:300,  discount:5,  price:300,  currency:"$",  productImages:["https://www.netmeds.com/images/product-v1/600x600/829558/aroma_magic_turmeric_cleanser_200_gm_0.jpg"]
  //   },
  //   {
  //     productCode:"33333333", productName:"Onetouch Verio Test Strip 50's",   productBrand:"Lifescan",  productCompany:"Lifescan",  shortDesc:"", longDesc:"Mkt: Morpheme Remedies Pvt Ltd <br/> Country of Origin: Canada<br/> Delivery charges if applicable will be applied at checkout", cost:1000,  discount:11,  price:1000,  currency:"",  productImages:["https://www.netmeds.com/images/product-v1/600x600/408329/onetouch_verio_test_strip_50_s_0.jpg"]
  //   },
  //   {
  //     productCode:"44444444", productName:"Nestle Resource Diabetic Powder - Vanilla Flavour 400 gm (Pet Jar)",   productBrand:"Nestle",  productCompany:"Nestle",  shortDesc:"", longDesc:"Mkt: Morpheme Remedies Pvt Ltd <br/> Country of Origin: Canada<br/> Delivery charges if applicable will be applied at checkout",  cost:749,  discount:5,  price:749,  currency:"$",  productImages:["https://www.netmeds.com/images/product-v1/600x600/14604/nestle_resource_diabetic_powder_vanilla_flavour_400_gm_pet_jar_0_1.jpg"]
  //   },
  //   {
  //     productCode:"55555555", productName:"INLIFE Diastan Plus Diabetic Care Powder 300 gm",   productBrand:"INLIFE Diastan",  productCompany:"INLIFE Diastan",  shortDesc:"", longDesc:"Mkt: Morpheme Remedies Pvt Ltd <br/> Country of Origin: Canada<br/> Delivery charges if applicable will be applied at checkout", cost:500,  discount:10,  price:500,  currency:10,  productImages:["https://www.netmeds.com/images/product-v1/600x600/858539/inlife_diastan_plus_diabetic_care_powder_300_gm_0.jpg"]
  //   },
  //   {
  //     productCode:"6666666", productName:"Moov Pain Relief Specialist Spray 50 gm",   productBrand:"Reckitt Benckiser",  productCompany:"Reckitt Benckiser",  shortDesc:"",  longDesc:"Mkt: Morpheme Remedies Pvt Ltd <br/> Country of Origin: Canada<br/> Delivery charges if applicable will be applied at checkout",  cost:180,  discount:5,  price:180,  currency:"$",  productImages:["https://www.netmeds.com/images/product-v1/600x600/15175/moov_pain_relief_specialist_spray_50_gm_0.jpg"]
  //   }
  // ];
}
