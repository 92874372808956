<div class="col-md-12">

  <div class="grid-container">
      <div style="text-align:left">
      <img style="width: 100%; margin-left:0px" src="../../assets/images/physiomain.png" />                                 
      
      </div>

      <div style="text-align: left;font-size: 18px;">
     
     
      
  
    <ul style="list-style-type:circle;  text-align: left;">
     <li>Is pain restricting your day-to-day activities?</li>
      <li>Need help deciding on treatment?</li>
      <li>Tired of all the painkiller tablets and want drug free natural treatment?</li>
    </ul>
    <br>
    <h3> Our Physiotherapists-<br></h3>
    <ul style="list-style-type:circle;  text-align: left;">
        <li>Combine their extensive knowledge of the human body and how it works with exceptional clinical skills to assess, diagnoses and treat injury, illness or disability.</li>
         <li>Treat symptoms and determine the root cause of an injury or disease resulting in greater positive response.</li>
         <li>Create specialized treatment plans</li>
       </ul>
       
    <br>
&nbsp;&nbsp;&nbsp;  <button style="font-size: 18px;" class="spabutton" [routerLink]="['/appointment/covid/physio']" (click)="serviceName('physio')" ><svg xmlns="http://www.w3.org/2000/svg" width="41" height="30" fill="none" viewBox="0 0 51 35">
<path fill="black" d="M0 25.463c0 .009 0 .009 0 0 0 1.01.813 1.831 1.822 1.831h28.355c1.01 0 1.823-.821 1.823-1.83V7.186H0v18.276zM23.94 9.018l.779.77-2.713 2.713-.77-.779 2.704-2.704zm-4.21 3.483c.42-.42 1.096-.411 1.506 0 .42.419.411 1.095 0 1.506l-.333.333-1.515-1.506.342-.333zm-2.55.855a.837.837 0 011.181 0l2.011 2.02a.837.837 0 010 1.18l-3.14 3.157-3.208-3.208 3.157-3.149zm-7.948 3.8a.542.542 0 01.77 0l1.677 1.676 1.575-1.566 3.208 3.209-1.566 1.574 1.677 1.677a.542.542 0 010 .77.542.542 0 01-.77 0l-2.9-2.9-1.883 1.89-.778-.77 1.882-1.89-1.215-1.215-1.677-1.677a.553.553 0 010-.779zm-.65 6.083c.53-.53 1.386-.53 1.916 0 .257.256.402.598.402.958 0 .744-.607 1.352-1.36 1.352-.36 0-.701-.146-.958-.394a1.353 1.353 0 010-1.916zM32 1.805A1.815 1.815 0 0030.177 0H27.21v2.533c0 .47-.386.855-.856.855a.858.858 0 01-.856-.855V0h-8.641v2.533c0 .47-.385.855-.856.855a.858.858 0 01-.856-.855V0H6.503v2.533c0 .47-.385.855-.856.855a.858.858 0 01-.856-.855V0H1.814A1.815 1.815 0 000 1.814v3.662h32v-3.67z"/>
</svg> Book An Appointment Now </button>
  <hr>

</div>
  </div>


<div class="col-md-12">
                <br>
<h2 style="text-align: center;"><strong>What We Offer</strong></h2>  <br>            

      <div class="grid-containersecond">
        <div class="grid-itemsecond">
            <img style="width: 100%; margin-left:0px" src="../../assets/images/Physitherapy.png" />                                 
                <h5 style="margin-top: 20px;"><b>Physiotherapy</b></h5>       
     </div>
      <div class="grid-itemsecond">
        <img style="width: 100%; margin-left:0px" src="../../assets/images/Ciro.png" />                                 
        <h5 style="margin-top: 20px;"><b>Chiropractic Therapy</b></h5>  
    </div>
        <div class="grid-itemsecond">
        <img style="width: 100%; margin-left:0px" src="../../assets/images/Massage.png" />                                 
        <h5 style="margin-top: 20px;"><b>Massage Therapy</b></h5>  
        </div>
      
      <div class="grid-itemsecond">
        <img style="width: 100%; margin-left:0px" src="../../assets/images/accupuncture.png" />                                 
        <h5 style="margin-top: 20px;"><b>Acupuncture</b></h5> 
    </div>
      </div>
    <hr style="text-align:center">
    <br>

      <h2 style="text-align:center"><strong> About Us</strong></h2>   
      <div class="grid-containerthird">
        <div style="text-align:left" >
<img style="width: 90%; " src="../../assets/images/stageOfPhysio1.png" />                                 
      </div>
        <div style="text-align:left;font-size:18px">

<p style="margin-top: 10px;">
    With the extensive knowledge of how the human body works with exceptional clinical skills,
     physiotherapists, chiropractors and other allied health professionals at our clinic will dive in depth  into the  injury and treat the root cause.
      After comprehensive history taking and a complete physical examination our highly trained professionals provide specialized and customized methods 
      to reduce the discomfort you are in and make your life a lot easier and happier. Make your first appointment and find out how we can help you.
</p>
       
      </div>
      
      <br>
    </div>
</div>

<hr>
 
<br>
<div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-4">
         <div style="width: 100%; padding: 0px 10px;">
            <h4 style="color: var(--chicago); font-family: var(--font-family-noto_sans); font-size: 27px;  font-style: normal; font-weight: 500;">
            Pharmacies Listed Under MyLocalRX</h4>
            <div style=" margin-top: 30px; margin-bottom: 10px; position: relative; 
             background-color: var(--white);border: 1px solid var(--blue-chill);border-radius: 3px;height: 33px;">
              <input [(ngModel)]="search" (input)="filterPharmacies()" placeholder="Enter Your Zip Code or City Name" id=placeholder type="text"
               style="width: 100%; outline: none;border: none;height: 31px; padding: 0px 10px;" />
                <div style="display: flex; position: absolute; top: 0; right: -1px; align-items: center;">
                     <img src="../../assets/new/loc.svg" />
                      <span style="padding: 0px 10px;color: #545665; font-family: var(--font-family-roboto); font-size: 9.8px; font-style: normal; 
                      font-weight: 400;">Locate Me</span> <img src="../../assets/new/locBtn.svg" /> </div> </div>
                       <div style=" margin-top: 0px; margin-bottom: 10px; position: relative;  background-color: var(--white);border: 1px solid var(--blue-chill);border-radius: 3px;height: 33px;">
                         <input placeholder="Radius" type="text" style="width: 100%; outline: none;border: none;height: 31px; padding: 0px 10px;" /> </div>
                         <div style="width: 100%; ;height: 375px; overflow-y: scroll;"> <div class="pharmacy" *ngFor="let item of copyPharmacies"> <label style=" margin: 0; color: var(--gravel); font-family: var(--font-family-roboto); font-size: 18px;
                          font-style: normal; font-weight: 500;">{{item.name}}</label><br />
                          <label style="color: var(--gravel); margin: 0; font-family: var(--font-family-roboto);  font-size: 15px; 
                           font-style: normal; font-weight: 400;">
                            <img src="../../assets/new/mp.svg" style="margin-right:10px;" />{{item.state}},{{item.pin}}</label> <div style="position: absolute; right: 0;bottom: 0;text-align: center;">
                                <img style="width: 22px;" src="../../assets/new/dir.svg" /><br>
                                 <span style="color: var(--blue-chill); font-family: var(--font-family-roboto); font-size: 10.2px; font-style: normal; font-weight: 400;">DIRECTIONS</span>
                            </div>
                         </div> </div>
                         </div> </div>
                         <div class="col-md-7">
                            <google-map height="575px" width="100%" [center]="center" [zoom]="zoom" [options]="options">
                                <map-marker *ngFor="let marker of markers" [position]="marker.position" [title]="marker.title">

                                </map-marker> </google-map> </div>
                            </div>


 


  