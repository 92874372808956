import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '../shared/models/user.model';
import { Pharmacy } from '../shared/models/pharmacy.model';
import { GlobalConstants } from '../shared/global-constants';

import { AuthenticationService } from '../services/authentication.service';
import { PharmacyService } from '../services/pharmacy.service';
import { AbstractControl, FormControl, NgForm, ValidationErrors, Validator } from '@angular/forms';
import { Route } from '@angular/compiler/src/core';
import { DeSanitizeMobileNumber } from "../directives/DeSanitizeMobileNumber";
import { DeSanitizeOHIPNumber } from "../directives/DeSanitizeOHIPNumber";
import { ProductService } from '../services/product.service';

declare var Compress: any;
declare var $: any;

@Component({
  selector: 'mylocalrx-contentcontainer-login',
  templateUrl: './mylocalrx-contentcontainer-login.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-login.component.scss']
})
export class MylocalrxContentcontainerLoginComponent implements OnInit {
  compress = new Compress();
  pharmacies: Array<Pharmacy> | undefined;
  selectedPharmacy: Pharmacy | undefined;
  selectedPharmacyID: string = "";
  selectedPharmacyAddress: string = "";
  signIN: boolean = false;
  checkout = false;
  forgotPaass: boolean = false;
  showLoginError: boolean = false;

  newUser: any;
  @Output() loginEvent = new EventEmitter();
  constructor(private authenticationService: AuthenticationService,
    private pharmacyService: PharmacyService, private actRoute: ActivatedRoute,
    private router: Router, private zone: NgZone, private deSanitizeMobileNumber: DeSanitizeMobileNumber, private deSanitizeOHIPNumber: DeSanitizeOHIPNumber, private productService: ProductService) {
    this.newUser = {
      id: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      email: "",
      phoneNumber: "",
      token: "",
      dateOfBirth: "",
      sex: 0,
      preferredPharmacy: "",
      ohip: "",
      allowSMSNotification: true,
      allowEmailNotification: true,
    }
  }

  ngOnInit(): void {
    $('.csLoader').css({ display: 'flex' });
    this.pharmacyService.getAllMyLocarxEnable().subscribe(data => {
      this.pharmacies = data.filter(x => x.myLocalRXEnabled == true);
      // if (this.pharmacies !== undefined && this.pharmacies.length > 0) {
      //   this.selectedPharmacy = this.pharmacies[0];
      //   this.selectedPharmacyID = this.pharmacies[0].id;
      //   this.selectedPharmacyAddress = this.selectedPharmacy.name + '\n ' + this.selectedPharmacy.add1 + '\n ' + this.selectedPharmacy.pin;
      // }
      $('.csLoader').hide();
    }, error => $('.csLoader').hide());



    var that = this;
    $(document).ready(function () {
      // $('.agedatepicker1').datepicker().on("changeDate", function (e: any) {
      //   that.zone.run(x => {
      //     that.newUser.dateOfBirth = $('.agedatepicker1').val();
      //   })
      // });
      $("#ohipFile").fileinput({
        showCaption: false,
        dropZoneEnabled: false,
        showUpload: false,
        maxFileCount: 5,
        allowedFileExtensions: ['jpg', 'jpeg', 'png', 'tiff', 'pdf'],    // allow only images
        showCancel: false,
        initialPreviewAsData: true,
        browseLabel: 'Upload OHIP...',
        color:"green",
        msgFileRequired: 'You must select OHIP Card copy to upload here.',
        msgFilesTooMany: "You can upload only 5 images"
      });
      $("#insuranceFile").fileinput({
        showCaption: false,
        dropZoneEnabled: false,
        showUpload: false,
        maxFileCount: 6,
        allowedFileExtensions: ['jpg', 'jpeg', 'png', 'tiff', 'pdf'],    // allow only images
        showCancel: false,
        initialPreviewAsData: true,
        browseLabel: 'Upload Insurance Card...',
        msgFileRequired: 'You must select insurance card copy to upload here.',
        msgFilesTooMany: "You can upload only 6 images"
      });
    });

    this.actRoute.params.subscribe(x => {
      this.signIN = x["id"] ? true : false;
      this.checkout = x["id"] == "checkout" ? true : false;
    });

  }

  openSignUPForm() {
    this.signIN = false;
    setTimeout(() => {
      $("#ohipFile").fileinput({
        showCaption: false,
        dropZoneEnabled: false,
        showUpload: false,
        maxFileCount: 5,
        allowedFileExtensions: ['jpg', 'jpeg', 'png', 'tiff', 'pdf'],    // allow only images
        showCancel: false,
        initialPreviewAsData: true,
        browseLabel: 'Upload OHIP...',
        color:"green",
        msgFileRequired: 'You must select OHIP Card copy to upload here.',
        msgFilesTooMany: "You can upload only 5 images"
      });
      $("#insuranceFile").fileinput({
        showCaption: false,
        dropZoneEnabled: false,
        showUpload: false,
        maxFileCount: 6,
        allowedFileExtensions: ['jpg', 'jpeg', 'png', 'tiff', 'pdf'],    // allow only images
        showCancel: false,
        initialPreviewAsData: true,
        browseLabel: 'Upload Insurance Card...',
        msgFileRequired: 'You must select insurance card copy to upload here.',
        msgFilesTooMany: "You can upload only 6 images"
      });



    }, 200);
  }


  openSignINForm() {
    this.signIN = true;
  }

  onPharmacySelect(pharmacyID: string): void {

    console.log('Pharmacy Selected : ' + pharmacyID);
    if (this.pharmacies !== undefined) {
      this.pharmacies.forEach(element => {
        console.log('Element : ' + element.id);
        if (element.id == pharmacyID) {
          console.log('ID macthed');
          this.selectedPharmacy = element;
          this.selectedPharmacyAddress = this.selectedPharmacy.name + ' \n' + this.selectedPharmacy.add1 + ' \n' + this.selectedPharmacy.pin;
        }
      });
    }

  }

  signup(form: NgForm) {
    var dt = new Date(this.newUser.dateOfBirth);
    var minDate = new Date(1900, 0, 1);
    var maxDate = new Date();
    if (dt < minDate || dt >= maxDate) {
      $('#dateOfBirth').focus();
      alert("Please enter a valid date, min. date JAN/01/1900");
      return;
    }
    if (!form.form.valid) {
      var invalid = false;
      for (const field in form.form.controls) {
        if (!invalid) {
          const control = form.form.get(field);
          if (control?.invalid) {
            control.markAsTouched();
            $('#' + field).focus();
            invalid = true;
          }
        }
      }
      return;
    }


    let ohipFile = $('#ohipFile')[0] ? this.ohipFiles : "";
    let insuranceFile = $('#insuranceFile')[0] ? this.insuranceFiles : null;
    GlobalConstants.isLoggedIn = false;

    var user = {
      firstName: this.newUser.firstName,
      lastName: this.newUser.lastName,
      password: this.newUser.password,
      email: this.newUser.email,
      phoneNumber: this.deSanitizeMobileNumber.transform(this.newUser.phoneNumber),
      dateOfBirth: this.newUser.dateOfBirth,
      sex: this.newUser.sex,
      bloodGroup: this.newUser.bloodGroup,
      preferredPharmacy: this.newUser.preferredPharmacy,
      ohip: this.deSanitizeOHIPNumber.transform(this.newUser.ohip),
      allowEmailNotification: this.newUser.allowEmailNotification,
      allowSMSNotification: this.newUser.allowSMSNotification,

    }
    if (!dt.getTime()) {
      $('#dateOfBirth').focus();
      return;
    }
    $('.csLoader').css({ display: 'flex' });
    this.authenticationService.signup(user, ohipFile, insuranceFile).subscribe(result => {
      console.log(result);
      $('.csLoader').hide();
      if (result.errorMessage) {
        $('#singupErrorMessage').modal('toggle');
      }
      else {
        $("#singupSuccessMessage").modal('toggle');
      }
      //this.signIN = true;

    }, error => { $('.csLoader').hide(); console.log(error); $('#singupErrorMessage').modal('toggle') });
  }

  signin(form: NgForm) {
    console.log(GlobalConstants.cartList);
    if (!form.form.valid) {
      for (const field in form.form.controls) {
        const control = form.form.get(field);
        if (control?.invalid) {
          control.markAsTouched();
        }
      }
      return;
    }
    $('.csLoader').css({ display: 'flex' });
    GlobalConstants.isLoggedIn = false;
    var _user = JSON.parse(JSON.stringify(this.newUser));
    if (_user.email.indexOf("@") <= 0) {
      _user.email = "+1" + _user.email;
    }
    this.authenticationService.signin(_user).subscribe(result => {
      $('.csLoader').hide();
      console.log(result);
      if (result.token) {
        this.newUser = result;
        //GlobalConstants.isLoggedIn = true;
        //GlobalConstants.currentUser = this.newUser;
        this.newUser.password = null;
        localStorage.setItem(GlobalConstants.CURRENT_USER, JSON.stringify(this.newUser));
        this.authenticationService.logInSuccess.next(this.newUser);
        //this.loginEvent.emit(true);
        //this.router.navigateByUrl('appointment');
        if (GlobalConstants.cartList && GlobalConstants.cartList.length > 0) {
          this.productService.mapMyCart(GlobalConstants.getCurrentSessionId(), this.newUser.id).subscribe(x => {
            setTimeout(() => {
              this.newUser.token = null;
              var url = this.checkout ? "checkout" : "";
              this.router.navigateByUrl(url);
            }, 200)
          });
        }
        else {
          setTimeout(() => {
            this.newUser.token = null;
            this.router.navigateByUrl('');
          }, 200);
        }
      }
      else {
        this.showLoginError = true;
        setTimeout(() => {
          this.showLoginError = false;
        }, 2000);
      }
      // else
      // {

      //   GlobalConstants.isLoggedIn = false;
      // }

    }, error => { $('.csLoader').hide(); console.log(error) });
  }
  resetPasswordError = false;
  resetPasswordMessage = "";
  forgotPassword(form: NgForm) {
    this.resetPasswordMessage = "";
    if (!form.form.valid) {
      for (const field in form.form.controls) {
        const control = form.form.get(field);
        if (control?.invalid) {
          control.markAsTouched();
        }
      }
      return;
    }
    $('.csLoader').css({ display: 'flex' });
    var eml = this.newUser.email;
    if (eml.indexOf("@") <= 0) {
      eml = "+1" + eml;
    }
    this.authenticationService.forgotPassword({ urlPath: window.location.origin, email: eml }).subscribe(x => {
      $('.csLoader').hide();
      this.resetPasswordError = x.errorCode;
      if (!x.errorCode) {
        $("#resetPasswordModal").click();
        this.resetPasswordMessage = x.successMessage;
        this.signIN = true;
        this.forgotPaass = false;
      }

    });
  }
  resetSuccessClick() {
    if (this.resetPasswordMessage.indexOf("OTP") > -1) {
      this.router.navigate(['/enter-otp/' + this.newUser.email]);
    }
  }
  ohipFiles: any[] = [];
  insuranceFiles: any[] = [];
  fileChangeEvent(event: any, id: string) {
    if (event.target.files) {
      const files1 = [...event.target.files];
      console.log(files1);
      this.compress.compress(files1, {
        size: 4,
        quality: .75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      }).then((data: any[]) => {
        console.log(data);
        var newFiles: any = [];
        data.forEach(element => {
          var nm = new Date().getTime() + "." + element.ext.split('/')[1];
          var baseString = element.data;
          if (baseString.indexOf("data") < 0) {
            baseString = "data:" + element.ext + ";base64," + baseString;
          }
          newFiles.push(this.base64ToFile(baseString, nm));
        });
        if (id === "ohipFile") {
          this.ohipFiles = newFiles;
        }
        else if (id === "insuranceFile") {
          this.insuranceFiles = newFiles;
        }
      })
    }
  }

  base64ToFile(dataURI: any, fileName: any): File {

    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], fileName, { type: mimeString });
  }
}

