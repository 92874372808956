<mylocalrx-header></mylocalrx-header>
<mylocalrx-scrollbacktotop></mylocalrx-scrollbacktotop>
<router-outlet></router-outlet>
<mylocalrx-footer></mylocalrx-footer>


<a id="btn_newLoginSession" data-toggle="modal"  data-backdrop="static" data-keyboard='false' data-target="#newLoginSession"></a>

<div class="modal fade" id="newLoginSession">
    <form #signinForm="ngForm" (ngSubmit)="signin(signinForm)">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Your session expired, please login...</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label>Enter your email or phone number <span style="color: red;">*</span></label>
                    <input [(ngModel)]="email"  name="email" required min="6" minlength="6" autofocus class="form-control" type="text" placeholder="Enter your email or phone number" />

                    <label style="margin-top: 15px;">Enter your password <span style="color: red;">*</span></label>
                    <input [(ngModel)]="password"  name="password"  required pattern=".{4,}" class="form-control" type="password" placeholder="Enter your password" />
                    <label style="    width: 100%;float: right;  margin-top: 15px;  color: red;  cursor: pointer">{{error}}</label>
                    <label data-dismiss="modal" (click)="clearAndForgot()"
                        style="float: right;  margin-top: 15px;  color: #007bff;  cursor: pointer">Forgot
                        Password?</label>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">
                        Login
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>